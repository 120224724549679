/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react';

import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';

import blockEvent from 'lib-frontend-shared/src/helpers/blockEvent';
import clamp from 'lib-frontend-shared/src/helpers/clamp';
import cls from 'lib-frontend-shared/src/helpers/cls';

import CircularProgress from 'lib-frontend-shared/src/components/CircularProgress';
import Collapse from 'lib-frontend-shared/src/components/Collapse';
import Divider from 'lib-frontend-shared/src/components/Divider';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';
import ResizeIcon from '../svg/resize.svg?react';

import { saveUserPref } from '../helpers/userPrefLocalStorage';

import theme from '../theme';
import './Table.scss';

const headerHeight = 50;

// if Input is a wrapper function, then creating component with
// JSX tag style (<Input />), causes text fields in the table to lose focus.
// So detect wrapper functions and call them like regular functions.
const renderComponent = (Input, props) => (
  typeof Input === 'function' ? Input(props) : <Input {...props} />
);

const maxWidth = 2048;
const minWidth = 64;

const onDrag = (gridRef, index, onChange) => ({
  clientX: origin, currentTarget: source,
}) => {
  const grid = gridRef.current;
  const element = source.parentNode;
  const { width } = element.getBoundingClientRect();

  const getWidth = (point) => `${
    clamp(width + point - origin, minWidth, maxWidth)
  }px`;

  source.setAttribute('active', '');

  const onPan = (event) => {
    blockEvent(event);
    grid.style['grid-template-columns'] = grid
      .style['grid-template-columns']
      .replaceAll(/,\s*/g, ',')
      .split(/\s+/)
      .map((val, pos) => (pos === index ? getWidth(event.clientX) : val))
      .join(' ');
  };

  const onEnd = (event) => {
    document.removeEventListener('pointermove', onPan);
    document.removeEventListener('pointerleave', onEnd);
    document.removeEventListener('pointerup', onEnd);
    source.removeAttribute('active');
    onChange(getWidth(event.clientX));
  };

  document.addEventListener('pointermove', onPan);
  document.addEventListener('pointerleave', onEnd);
  document.addEventListener('pointerup', onEnd);
};

const TableHeader = ({
  align,
  children,
  columnId,
  gridRef,
  color,
  index,
  isDesc,
  isSelected,
  isSticky,
  lastElement,
  fixedPositionFromStart,
  selectable,
  onClick,
  onResize,
  variant,
}) => (
  <div
    className={cls('Table-headerCell', {
      align,
      color,
      lastElement,
      isSticky,
      variant,
      fixedColumn: Boolean(fixedPositionFromStart),
    })}
    style={fixedPositionFromStart ? { left: fixedPositionFromStart } : {}}
  >
    { selectable ? children : (
      <>
        <button
          className={cls('Table-headerButton', { isDesc, isSelected })}
          onClick={onClick}
          type="button"
          disabled={!onClick}
        >
          {children}
        </button>
        {onResize && (
          <div
            className={cls('Table-resizer', { color })}
            onPointerDown={onDrag(gridRef, index, (value) => onResize(columnId, value))}
          >
            <ResizeIcon className="Table-resizerIcon" />
          </div>
        )}
      </>
    )}
  </div>
);

export const getColumnResizeKey = (storageKey) => {
  const storageKeyPrefix = `Table::${storageKey}`;
  return `${storageKeyPrefix}::column-size`;
};

/**
 * @typedef {Object} TableColumnConfig
 * @property {string} columnId - The unique identifier for the column.
 * @property {string} label - The label to display in the column header.
 * @property {boolean} [hideLabel=false] - Whether to hide the column label.
 * @property {boolean} [selectable=false] - Whether the column is selectable.
 * @property {boolean} [sortable=true] - Whether the column is sortable.
 * @property {('left'|'center'|'right')} [align='center'] - The alignment of the column content.
 * @property {React.ReactNode} [labelComponent] - A custom component to render in the column header.
 * @property {React.CSSProperties} [style] - Custom inline styles for the column.
 * @property {boolean} [resizable=true] - Whether to allow the column to be resized.
 */

/**
 * @typedef {Object} TableRow
 * @property {any} rowId - Unique identifier for the row, derived from the row data or specified rowIdField.
 * @property {boolean} [isRowSelected] - Indicates if the row is selected.
 * @property {any} [otherProps] - Other properties from the row data that may be used in rendering or logic.
 */

/**
 * @param {Object} props - The component props
 * @param {TableColumnConfig[]} props.columns - The table columns configuration
 * @param {TableRow[]} props.rows - The table rows data
 * @param {boolean} [props.noDivider] - Whether to hide the divider between rows
 * @param {'sm'|'md'|'lg'} [props.cellSize='lg'] - The size of the table cells. Default 'lg'
 * @param {string} [props.className] - Additional class name for the table
 * @param {string} [props.rowIdField] - The field to use as the row ID
 * @param {boolean} [props.columnsSortableByDefault=true] - Whether columns are sortable by default
 * @param {boolean} [props.disableSort=false] - Whether to disable sorting. Default false
 * @param {object} [props.labelComponentProps={}] - Common props to all label components
 * @param {string} [props.sortBy] - The field to sort by
 * @param {'asc'|'desc'} [props.sortDirection] - The sort direction
 * @param {string} [props.storageKey] - The key to use for local storage
 * @param {boolean} [props.loading] - Whether the table is loading data
 * @param {boolean} [props.margin=true] - Whether to add margin to the table. Default true
 * @param {Function} [props.onChange] - Callback when the table data changes
 * @param {Function} [props.onRowsChange] - Callback when the table rows change
 * @param {boolean} [props.cardBleed=false] - Whether to allow the table to bleed outside its container. Default false
 * @param {boolean} [props.stickyHeader=false] - Whether to make the table header sticky. Default false
 * @param {Object} [props.style={}] - Additional inline styles for the table
 * @param {Object} [props.header] - Table header configuration
 * @param {'primary'|'secondary'} [props.header.color='secondary'] - The color of the table header
 * @param {boolean} [props.header.hide=false] - Whether to hide the table header. Default false
 * @param {Object} [props.columnSize={}] - The size of each column
 * @param {Function} [props.setColumnSize] - Callback to set the size of each column
 * @param {'card'|'outlined'} [props.variant] - The table variant
 *
 */
const Table = ({
  noDivider,
  store, // Is this param used anywhere in code base?
  cellSize = 'md',
  className,
  columns,
  rows = [],
  rowIdField,
  columnsSortableByDefault = true,
  disableSort,
  loading,
  labelComponentProps = {},
  sortBy,
  sortDirection,
  storageKey,
  margin = true,
  onChange,
  onRowsChange,
  cardBleed = false,
  stickyHeader = false,
  style = {},
  header: {
    color: headerColor = 'secondary',
    hide: hideHeader = false,
  } = {},
  columnSize = {},
  setColumnSize = () => {},
  variant = 'card',
  autoHeightAdjustment = false,
  rowVariant = 'standard',
}) => {
  const columnStorageKey = getColumnResizeKey(storageKey);
  const gridRef = useRef(null);
  const placeholderRef = useRef(null);
  const [tableHeightStyle, setTableHeightStyle] = useState(autoHeightAdjustment ? { height: 'auto', minHeight: 'auto' } : {});
  const totalRows = rows?.length;
  useEffect(() => {
    if (!autoHeightAdjustment) return () => {};
    const adjustTableHeight = () => {
      const tableElement = gridRef.current;
      const rowHeight = tableElement ? tableElement.querySelector('.Table-cell')?.offsetHeight : 0;
      const viewportHeight = window.innerHeight;

      const offsetTop = tableElement.getBoundingClientRect().top;
      const availableHeight = viewportHeight - offsetTop;

      if (rowHeight) {
        const requiredHeight = 20 + headerHeight + (rowHeight * Math.min(10, totalRows));
        let updatedTableHeightStyle = {};
        if (totalRows <= 10 || availableHeight < requiredHeight) {
          updatedTableHeightStyle = { height: 'auto', minHeight: `${requiredHeight}px` };
        } else {
          updatedTableHeightStyle = { height: '100%', minHeight: 'auto' };
        }
        setTableHeightStyle(updatedTableHeightStyle);
      } else {
        setTableHeightStyle({ height: 'auto', minHeight: 'auto' });
      }
    };

    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);

    return () => {
      window.removeEventListener('resize', adjustTableHeight);
    };
  }, [totalRows]);


  const onResize = storageKey ? ((field, value) => {
    const update = { ...columnSize, [field]: value };
    saveUserPref(columnStorageKey, update);
    setColumnSize(update);
  }) : undefined;

  const rowsEnriched = rows.flatMap((row, index) => [
    ...columns.map(({
      align = 'center',
      CellComponent,
      columnId,
      dataTestId,
      fixedPositionFromStart,
    }, colIndex) => ({
      row: {
        ...row, rowId: row.rowId || row[rowIdField],
      },
      firstElement: colIndex === 0,
      lastElement: colIndex === columns.length - 1,
      fixedColumn: Boolean(fixedPositionFromStart),
      fixedPositionFromStart,
      rowIndex: index,
      CellComponent,
      columnId,
      dataTestId,
      align,
    })),
    ...columns.filter(({ ExpandComponent }) => ExpandComponent).map(
      ({ columnId, ExpandComponent, expand = () => true }) => ({
        row: { ...row, rowId: row.rowId || row[rowIdField] },
        rowIndex: index,
        columnId,
        ExpandComponent,
        expand,
      }),
    ),
    ...(noDivider || index === (rows.length - 1) ? [] : [{ divider: true, rowIndex: index }]),
  ]);
  const fullSpan = { gridColumn: `1 / ${columns.length + 1}` };

  return (
    <div style={tableHeightStyle} className={cls('Table', { margin, variant, isSticky: stickyHeader }, className)}>
      {!hideHeader && (
        <div
          className={cls('Table-headerBackground', {
            color: headerColor,
            isSticky: stickyHeader,
            variant,
          })}
        />
      )}
      <div
        className={cls('Table-content', { showHeader: !hideHeader })}
        ref={gridRef}
        style={{
          ...style,
          ...(cardBleed ? {
            marginLeft: `-${2 * theme.spacing()}px`,
            marginRight: `-${2 * theme.spacing()}px`,
            width: `calc(100% + ${4 * theme.spacing()}px)`,
          } : {}),
          gridTemplateColumns: columns
            .map(({ columnId, width }) => columnSize[columnId] || width)
            .join(' ').trim() || `repeat(${columns.length}, minmax(auto, 1fr))`,
        }}
      >
        {!hideHeader && columns.map(({
          columnId,
          label,
          hideLabel,
          selectable = false,
          sortable = columnsSortableByDefault,
          align = 'center',
          labelComponent: LabelComponent,
          style: columnStyle,
          resizable = true,
          fixedPositionFromStart,
        }, index) => (
          <TableHeader
            align={align}
            color={headerColor}
            columnId={columnId}
            gridRef={gridRef}
            index={index}
            isDesc={sortDirection === 'desc'}
            isSelected={columnId === sortBy}
            isSticky={stickyHeader}
            key={columnId}
            fixedPositionFromStart={fixedPositionFromStart}
            variant={variant}
            lastElement={index === columns.length - 1}
            selectable={selectable}
            onClick={onChange && sortable && !disableSort ? (() => onChange({
              sortBy: columnId,
              sortDirection: (
                columnId === sortBy
                    && sortDirection === 'asc'
              ) ? 'desc' : 'asc',
              page: 0,
            })) : undefined}
            // TODO: do we need to always disable resizing for the last column?
            onResize={resizable && index < columns.length - 1 ? onResize : undefined}
          >
            <div
              className={cls('Table-tableHeaderFontStyling', { color: headerColor })}
              style={columnStyle}
            >
              {LabelComponent ? ( // eslint-disable-line no-nested-ternary
                typeof LabelComponent === 'function' ? <LabelComponent {...labelComponentProps} /> : LabelComponent
              ) : (
                <>
                  {!hideLabel && label}
                  {(sortable && !disableSort) && columnId === sortBy && (
                    sortDirection === 'desc'
                      ? <ArrowDropDownIcon fontSize="small" />
                      : <ArrowDropUpIcon fontSize="small" />
                  )}
                </>
              )}
            </div>
          </TableHeader>
        ))}
        {stickyHeader && <div ref={placeholderRef} className="Table-spanRowWrapper" />}
        {rowsEnriched.map(({
          align,
          CellComponent,
          columnId,
          dataTestId = () => undefined,
          divider,
          expand = () => true,
          ExpandComponent,
          row,
          rowIndex,
          firstElement,
          lastElement,
          fixedColumn,
          fixedPositionFromStart,
        }) => {
          if (divider) return <Divider key={`row-${rowIndex}-divider`} style={fullSpan} />;
          const componentProps = {
            store,
            row,
            rowIndex,
            columnId,
            align,
            onChange: (updatedRow) => onRowsChange(rows.map((existingRow) => {
              const existingRowId = existingRow.rowId || existingRow[rowIdField];
              const updatedRowRowId = updatedRow.rowId || updatedRow[rowIdField];
              if (existingRowId === updatedRowRowId) {
                return updatedRow;
              }
              return existingRow;
            })),
          };
          const isExpanded = expand(componentProps);
          if (ExpandComponent) {
            const renderedComponent = renderComponent(ExpandComponent, componentProps);
            const hasNoData = !React.Children.count(renderedComponent?.props?.children) > 0;
            return (
              <div className={cls('Table-expandedCell', { rowVariant, isExpanded, hasNoData })} style={fullSpan}>
                <Collapse key={`${columnId}-${rowIndex}-expansion`} in={isExpanded}>
                  {renderedComponent}
                </Collapse>
              </div>
            );
          }
          return (
            <div
            // ignore react/no-array-index-key as index+columnId gives the most stable
            // keys as rowId could change (if table's rowId column is editable).
            // stable keys is needed to for text fields to not lose focus
            // eslint-disable-next-line react/no-array-index-key
              key={`${rowIndex}_${columnId}`}
              data-testid={dataTestId(componentProps)}
              className={cls('Table-cell', {
                align,
                size: cellSize,
                variant,
                isSelected: row.isRowSelected,
                firstElement,
                lastElement,
                fixedColumn,
                rowVariant,
                isExpanded,
              })}
              style={fixedPositionFromStart ? { left: fixedPositionFromStart } : {}}
            >
              {/* This is for to highlight the selected element */}
              {renderComponent(CellComponent, componentProps)}
              {firstElement
              && <div className={cls('Table-rowWrapper', { size: cellSize })} />}
            </div>
          );
        })}
      </div>
      {!rows?.length && (
        <Linear align="center" gap="lg" orientation="vertical" width="100pr" style={{ marginTop: '10px' }}>
          <div />
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography variant="para.sm:body">
              No records
            </Typography>
          )}
          <div />
        </Linear>
      )}
    </div>
  );
};

export default Table;
