import React, { useRef, useState } from 'react';
import { camelCase, map } from 'lodash';
import {
  Menu as MuiMenu,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { MoreHoriz, KeyboardArrowRight } from '@material-ui/icons';
import cls from 'lib-frontend-shared/src/helpers/cls';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Typography from 'lib-frontend-shared/src/components/Typography';
import IconButton from './IconButton';
import './Menu.scss';

const useStyles = makeStyles(() => ({
  subMenuPaper: (props) => ({
    borderRadius: '20px',
    padding: '4px 10px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.25)',
    minWidth: '150px',
    ...props,
  }),
}));
const StandardButton = (props) => {
  const {
    color,
    disabled,
    onClick,
    isMenuOpen = false,
    style = {},
    size = 'medium',
    tooltip = 'Actions',
    variant,
    Icon = MoreHoriz,
    ...rest
  } = props;
  return (
    <IconButton
      {...rest}
      color={color}
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={cls('Menu-button', { active: isMenuOpen })}
      variant={variant}
      style={style}
      Icon={Icon}
      tooltip={tooltip}
    />
  );
};
const defaultSelectedSubMenuConfig = { parentKey: '', subMenuOptions: [] };
const Menu = (props) => {
  const {
    'data-testid': testId,
    id,
    options: rawOptions = [],
    variant,
    color,
    disabled,
    menuButtonProps = {},
    onClick = () => {},
    style,
    size,
    Component,
    hideEmpty = true,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(defaultSelectedSubMenuConfig);
  const { parentKey = '', subMenuOptions: selectedSubMenuOptions = [] } = selectedSubMenu;
  const key = `Menu-${id || ''}`;
  const isOpen = Boolean(anchorEl);
  const isSubMenuOpen = Boolean(subMenuAnchorEl);
  const close = () => setAnchorEl(null);
  const closeSubMenu = () => {
    setSubMenuAnchorEl(null);
    setSelectedSubMenu(defaultSelectedSubMenuConfig);
  };
  const menuRef = useRef(null);
  const [subMenuRenderPosition, setSubMenuRenderPosition] = useState({ onLeft: {}, onRight: {} });
  const options = rawOptions.filter(({ show = true }) => show);
  const noOptions = options.length === 0;
  const classes = useStyles(subMenuRenderPosition.onRight);
  React.useEffect(() => {
    if (menuRef.current) {
      // NOTE: There is no way to pass a ref to Mui Menu paper component, so to
      // get the Paper element we need this class base selector
      const childElement = menuRef.current.querySelector('.Menu-paper');
      if (childElement) {
        const childElementBoundary = childElement.getBoundingClientRect();
        const parentWidth = parseFloat(window.getComputedStyle(childElement).width.split('px')[0]);

        const spaceOnRight = window.innerWidth - childElementBoundary.right;
        if (spaceOnRight > 150) {
          setSubMenuRenderPosition({ onLeft: { left: parentWidth + 15 } });
        } else {
          setSubMenuRenderPosition({ onRight: { right: `${parentWidth + 42}px !important`, left: 'unset !important' } });
        }
      }
    }
  }, [anchorEl, menuRef.current]);

  if (hideEmpty && noOptions) return null;
  const MenuButton = Component || StandardButton;
  return (
    <>
      <MenuButton
        {...menuButtonProps}
        key={key}
        color={color}
        data-testid={testId}
        isMenuOpen={isOpen}
        disabled={disabled || noOptions}
        onClick={({ target }) => {
          setAnchorEl(target);
          onClick();
        }}
        style={style}
        size={size}
        variant={variant}
      />
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        classes={{
          paper: 'Menu-paper',
        }}
        ref={menuRef}
        onClose={() => setAnchorEl(null)}
      >
        {options.map(({
          /* eslint-disable no-shadow */
          key: customKey,
          label,
          Icon,
          onClick,
          subMenu = [],
          color: colorFromProps = 'secondary',
          style,
          disabled = false,
        }) => {
          const composedParentKey = `${key}-${customKey || label}`;
          const isParentMenuSelected = subMenu.length && parentKey === composedParentKey;
          const color = isParentMenuSelected ? 'canvas' : colorFromProps;
          return disabled ? (
            <Typography className="Menu-disabledItem" key={composedParentKey} variant="para.sm:body">
              {label}
            </Typography>
          ) : (
            <MenuItem
              classes={{
                root: 'Menu-item',
                selected: 'Menu-selected',
              }}
              className={cls('Menu', { selected: isParentMenuSelected })}
              data-testid={testId ? `${testId}-${camelCase(label)}` : undefined}
              disabled={!onClick}

              key={composedParentKey}
              onClick={async (event) => {
                if (subMenu.length) {
                  setSubMenuAnchorEl(event.currentTarget);
                  setSelectedSubMenu({ parentKey: composedParentKey, subMenuOptions: subMenu });
                } else {
                  setSelectedSubMenu(defaultSelectedSubMenuConfig);
                  if (onClick) await onClick(event);
                  close();
                }
              }}
            >
              <Linear width="100Pr" align="center" justify="space" gap="md">
                <Linear align="center" gap="md">
                  {Icon && <Icon color={color} style={style} />}
                  <Typography variant="para.md" color={color}>
                    {label}
                  </Typography>
                </Linear>
                {subMenu.length > 0 && <KeyboardArrowRight className="Menu-rightIcon" color={color} fontSize="medium" />}
              </Linear>
            </MenuItem>
          );
        })}
      </MuiMenu>
      {selectedSubMenuOptions.length > 0 && (
        <MuiMenu
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={isSubMenuOpen}
          classes={{
            paper: classes.subMenuPaper,
          }}
          style={{ ...subMenuRenderPosition.onLeft }}
          onClose={closeSubMenu}
        >
          {map(
            selectedSubMenuOptions,
            ({
              /* eslint-disable no-shadow */
              key: customKey,
              label,
              Icon,
              onClick,
              color,
              style,
              disabled = false,
            }) => (disabled
              ? <Typography variant="para.sm:body" className="Menu-disabledItem" key={`${key}-submenu-${customKey || label}`}>{label}</Typography>
              : (
                <MenuItem
                  classes={{
                    root: 'Menu-item',
                    selected: 'Menu-selected',
                  }}
                  key={`${key}-submenu-${customKey || label}`}
                  onClick={(event) => {
                    if (onClick) onClick(event);
                    closeSubMenu();
                    setAnchorEl(null);
                  }}
                >
                  {Icon && (
                    <>
                      <Icon color={color} style={style} />
                      <Spacer x="md" />
                    </>
                  )}
                  <Typography variant="para.md" color={color} style={style}>
                    {label}
                  </Typography>
                </MenuItem>
              )),
          )}
        </MuiMenu>
      )}
    </>
  );
};

export default Menu;
